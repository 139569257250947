import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';
// const apiDcHost = 'api-dc';
const apiFlowHost = 'api-iflow';

// 查询会计主体-(旧)
export const queryEfOrganRelationsApi = (params) => {
    return request({
        url: apiCrmHost + '/efOrganRelations',
        method: 'get',
        params
    });
};

// 根据会计主体实体名称查询会计实体Id
export const getSrmCompanyInfosApi = (data) => {
    return request({
        url: 'api-mdm/public/srm/srmCompanyInfos',
        method: 'post',
        data
    });
};
// 印章管理员查询
export const getSealAdminList = (params) => {
    return request({
        url: apiCrmHost + '/contract/querySealAdmin',
        method: 'post',
        data: params
        // headers: {
        //     'Content-Type': 'application/json'
        // }
    });
};
// ------------------------pyh-add
// 合同保存-保存主合同信息
export const contractInfoSaveApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/info/save',
        method: 'post',
        data
    });
};

// 根据id查询合同详情
export const getContractInfoByIdApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/info/getById',
        method: 'get',
        params: data
    });
};
// 补充协议/终止协议生成接口
export const supplementaryAddApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/supplementary/agreement/add',
        method: 'post',
        data
    });
};
// 合同管理-提交审批流程-法务（新签、续签、补充协议/终止协议）
export const createFlowApi = (data) => {
    return request({
        url: apiCrmHost + '/contractInfo/createFlow',
        method: 'post',
        data
    });
};
// mipIframe 流程页面mip提交按钮-（新签、续签、补充协议/终止协议）mip提交-法务
export const iflowSubmitApi = (data) => {
    return request({
        url: apiCrmHost + '/contractInfo/submit',
        method: 'post',
        data
    });
};

// mipIframe 流程页面mip提交按钮-合同日期变更-法务
export const submitByFwApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/change/submitByFw',
        method: 'post',
        data
    });
};
// 合同管理-提交审批流程-非法务(预签)
export const notFWcreateFlowApi = (data) => {
    return request({
        url: apiCrmHost + '/contractInfo/iflow/createFlow',
        method: 'post',
        data
    });
};
// mipIframe 流程页面mip提交按钮-非法务
export const approveProcess = (params) => {
    return request({
        url: apiFlowHost + `/approveProcess?tenantCode=${params.tenantCode}&appCode=${params.currentApplicationCode}`,
        method: 'post',
        data: params
    });
};
// 查询mip流程
export const queryOneInstance = (params) => {
    return request({
        url: apiFlowHost + `/queryOneInstance`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 其他变更请求校验接口
export const otherChangeApplyApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/change/apply',
        method: 'post',
        data
    });
};

// 其他变更保存
export const otherChangeAddApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/change/add',
        method: 'post',
        data
    });
};

// 通过id查询变更合同详情
export const contractChangeDetailApi = (params) => {
    return request({
        url: apiCrmHost + '/contract/change/getById',
        method: 'get',
        params
    });
};

// 变更提交
export const contractChangeUpdateStatusApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/change/updateStatus',
        method: 'post',
        data
    });
};
// 变更审批通过
export const contractChangeUpdateContractApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/change/updateContract',
        method: 'post',
        data
    });
};
// 变更审批驳回
export const contractChangeRejectApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/change/reject',
        method: 'post',
        data
    });
};
// 查询商机列表
export const searchNeoCrmOpportunityApi = (data) => {
    return request({
        url: apiCrmHost + '/searchNeoCrmOpportunity',
        method: 'post',
        data
    });
};
// 合同预填充接口
export const searchContractByOtherApi = (data) => {
    return request({
        url: apiCrmHost + '/searchContractByOther',
        method: 'post',
        data
    });
};
// 通过其他变更编码查询变更合同详情
export const contractChangeDetailGetByCodeApi = (params) => {
    return request({
        url: apiCrmHost + '/contract/change/getByCode',
        method: 'get',
        params
    });
};
// 合同归档
export const contractArchiveInfoSaveApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/archive/info/save',
        method: 'post',
        data
    });
};
// 其他变更-变更提交-完成代办处理
export const priceOtherFinsh = (params) => {
    return request({
        url: apiCrmHost + '/contract/change/todo/finish',
        method: 'get',
        params
    });
};
// 其他价格变更-代办判断是否有权限
export const priceOtherAuth = (params) => {
    return request({
        url: apiCrmHost + '/contract/change/todo/auth',
        method: 'get',
        params
    });
};
// 提交第三方签章
export const pushFileOther = (params) => {
    return request({
        url: apiCrmHost + '/contractEnclosureInfo/pushFile/JDSK',
        method: 'get',
        params
    });
};
// 推送智汇签
export const pushFileSignature = (params) => {
    return request({
        url: apiCrmHost + '/contractEnclosureInfo/pushFile/ZHQ',
        method: 'get',
        params
    });
};
