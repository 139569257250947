<!-- eslint-disable max-lines -->
<template>
    <div class="contract-flow">
        <Paper class="contract-flow-paper" v-if="isShowTips">
            <span class="tips">
                请切换LCRM合同管理模块查询：{{contractCode}}
            </span>
        </Paper>
    </div>
</template>
<script>
import Paper from '@/components/core/Paper';
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api';
import { priceOtherAuth } from '@mdm/api/contractManage/detailMainApi.js';
import store from '@/store';
export default {
    name: 'approveFlow',
    components: { Paper },
    /* eslint-disable max-lines-per-function */
    setup () {
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const contractCode = ref(null); // APSS20000039W
        const contractChangeCode = ref(null);
        const isShowTips = ref(false);
        onMounted(() => {
            getAuthHandle();
        });
        const getAuthHandle = () => {
            const { fdFlowId } = ctx.$route.query;
            contractCode.value = ctx.$route.query.contractCode;
            contractChangeCode.value = ctx.$route.query.contractChangeCode;
            fdFlowId && priceOtherAuth({ fdFlowId }).then(res => {
                if (res && +res.code === 0) {
                    // 记录当前代办的参数，用于打开合同管理-合同详情、变更单详情
                    store.commit('getContractManageParams', ctx.$route.query);
                    store.dispatch('delAllViews'); // 关闭页签
                    ctx.$router.push('/mod/mdm/contract-manage');
                } else {
                    isShowTips.value = true;
                }
            }).catch(() => {
                isShowTips.value = true;
            });
        };
        return {
            contractCode,
            isShowTips
        };
    }
};
</script>
<style lang="less" scoped>
.contract-flow{
    width: 100%;
}
.contract-flow-paper{
    width: 100%;
    height: 100%;
}
.tips{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8C8C8C;
    font-size: 24px;
    margin-top: -40px;
}
</style>
